<template>
    <section id="description-list-alignment">
        <b-row class="match-height">
            <b-col cols="12">
                <b-card>
                    <b-card-title>{{ $t("Journals") }} </b-card-title>
                    <b-row>
                        <b-col md="8">
                            <b-row>
                                <b-col md="6" v-for="item in journals" :key="item.id">
                                    <b-card :img-src="getImage(item.image)" overlay text-variant="white" img-alt="card img"
                                        body-class="bg-overlay">
                                        <b-card-title class="text-white">
                                            {{ item.title }}
                                        </b-card-title>
                                        <b-card-text>
                                            {{ item.description }}
                                        </b-card-text>
                                        <b-button pill @click="getIssue(item.id)">
                                            {{ $t('More Information') }}
                                        </b-button>

                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="4" v-if="issues.length > 0">
                            <section id="description-list-alignment">
                                <b-row class="match-height">
                                    <b-col cols="12">
                                        <b-card>
                                            <b-card-title>{{ $t("Archives") }} </b-card-title>
                                            <app-collapse accordion type="margin" v-for="item in issues" :key="item.id">
                                                <app-collapse-item :title=item.begin>
                                                    <b-card-text v-for="item in item.issues" :key="item.id">
                                                        <b-link @click="getA(item.id)">
                                                            {{ $t("volumn") }} : {{ item.title }}
                                                        </b-link>
                                                    </b-card-text>
                                                </app-collapse-item>
                                            </app-collapse>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </section>

                        </b-col>
                        <b-col md="4" v-if="articles.length > 0" v-for="item in articles" :key="item.id">
                            <b-card border-variant="primary" :title=item.title bg-variant="transparent" class="shadow-none">
                                <b-row style="text-align: center;">
                                    <b-col md="12" >
                                        <b-card-text>
                                            {{ item.authors }}
                                        </b-card-text>
                                    </b-col>
                                    <b-col md="6">
                                        {{ $t("Year") }} : {{ item.year.begin }}
                                    </b-col>
                                    <b-col md="6">
                                        {{ $t("volumn") }} : {{ item.issue.title }}
                                    </b-col>
                                    <b-col md="12" >
                                        <b-link :to="{ name: 'details', params: { id: item.id } }">
                                        <b-card-text>
                                        <span><feather-icon icon="EyeIcon" class="mr-50"/></span>{{ $t("Details") }}  
                                        </b-card-text>
                                    </b-link>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <!--/ Description lists horizontal -->

        </b-row>
    </section>
</template>
  
<script>
import {
    BRow, BCol, BCard, BCardTitle, BButton, BCardText, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { filesUrl, Url } from "@/main.js";
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
    BFormRadioGroup, BFormRadio, BFormGroup, BLink
} from 'bootstrap-vue'
import { codeCollapseTypes } from './code'
export default {
    components: {
        BRow,
        BCol, Url,
        BCard, BButton,
        BCardTitle, BLink,
        BCardText,
        BCardHeader, AppCollapseItem, AppCollapse, BCardCode,
        BCardBody, BFormRadioGroup, BFormRadio, BFormGroup
    },
    data() {
        return {
            journals: [],
            issues: [],
            articles: [],
            perPage: 12,
            currentPage: 0,
        }
    },
    mounted() {
        this.getjournals()
    },
    methods: {
        async getjournals() {
            await fetch(
                `${Url}journals?take=${this.perPage}&skip=${this.currentPage - 1}`,
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.journals = [];
                    this.journals = data.items;
                });
        },
        async getIssue(id) {
            await fetch(
                `${Url}archive/issue/${id}`,
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.issues = [];
                    this.issues = data.items;
                });
        },
        getImage(path) {
            return `${filesUrl}/${path}`;
        },
        async getA(id) {
            await fetch(
                `${Url}articles?issue_id=${id}&take=${1000}&skip=0`,
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.articles = [];
                    this.articles = data.items;
                    console.log(this.articles)
                });
        }
    },
}
</script>
  